<template>
  <v-card class="pa-4 admin-report-card">
    <v-row class="report-wrap">
      <v-col
        md="4"
        sm="12"
        cols="12"
        class="d-flex align-center">
        <p class="text-h6">
          Online Singapore Sales Report
        </p>
      </v-col>
      <v-col
        md="3"
        sm="12"
        cols="12"
        class="d-flex align-center">
        <v-select
          v-model="brandSelected"
          label="เลือก Brand"
          :items="allBrand"
          :disabled="fetching"
          outlined
          auto-select-first
          dense
          class="mr-2"
          hide-details
          @input="fetchReport" />
        <v-select
          v-model="channel"
          :items="channels"
          :disabled="fetching"
          :menu-props="{ offsetY: true }"
          item-value="value"
          item-text="label"
          label="Channel"
          auto-select-first
          outlined
          hide-details
          dense
          @change="fetchReport()" />
      </v-col>
      <v-col
        md="4"
        sm="12"
        cols="12"
        class="d-flex align-center">
        <date-picker
          v-model="dateSelected"
          :disabled="fetching" />
      </v-col>
      <v-col cols="1">
        <v-btn
          class="mt-2"
          :loading="fetching"
          @click="fetchReport()">
          Go
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        class="d-flex flex-wrap justify-center">
        <v-card
          outlined
          class="ma-4">
          <v-card-text class="text-center">
            <div>Count Bill</div>
            <p class="title text--primary">
              {{ summary.countBill | showNumberFormat() }}
            </p>
          </v-card-text>
        </v-card>
        <v-card
          outlined
          class="ma-4">
          <v-card-text class="text-center">
            <div>Total Items</div>
            <p class="title text--primary">
              {{ summary.totalItem | showNumberFormat() }}
            </p>
          </v-card-text>
        </v-card>
        <v-card
          outlined
          class="ma-4">
          <v-card-text class="text-center">
            <div>Total Full Price (SGD)</div>
            <p class="title text--primary">
              {{ summary.totalFullPrice | showFullPriceFormat() }}
            </p>
          </v-card-text>
        </v-card>
        <v-card
          outlined
          class="ma-4">
          <v-card-text class="text-center">
            <div>Total Unit Discount (SGD)</div>
            <p class="title text--primary">
              {{ summary.totalUnitDiscount | showFullPriceFormat() }}
            </p>
          </v-card-text>
        </v-card>
        <v-card
          outlined
          class="ma-4">
          <v-card-text class="text-center">
            <div>Total Bill Discount (SGD)</div>
            <p class="title text--primary">
              {{ summary.totalBillDiscount | showFullPriceFormat() }}
            </p>
          </v-card-text>
        </v-card>
        <v-card
          outlined
          class="ma-4">
          <v-card-text class="text-center">
            <div>Total ship cost (SGD)</div>
            <p class="title text--primary">
              {{ summary.totalShipCost | showFullPriceFormat() }}
            </p>
          </v-card-text>
        </v-card>
        <v-card
          outlined
          class="ma-4">
          <v-card-text class="text-center">
            <div>Total Bill Net (SGD)</div>
            <p class="title text--primary">
              {{ summary.totalBillNet | showFullPriceFormat() }}
            </p>
          </v-card-text>
        </v-card>
        <v-card
          outlined
          class="ma-4">
          <v-card-text class="text-center">
            <div>Total Bill Net THB (Rate 23.82)</div>
            <p class="title text--primary">
              {{ summary.totalBillNetTHB | showFullPriceFormat() }}
            </p>
          </v-card-text>
        </v-card>
        <v-btn
          color="success"
          min-width="15%"
          class="mx-2 mt-9"
          :loading="exportLoading || fetching"
          @click="exportCsv">
          <v-icon class="mr-1">
            mdi-export
          </v-icon>
          Export CSV
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          :items-per-page="options.itemsPerPage"
          :server-items-length="total"
          :options.sync="options"
          :loading="fetching"
          dense
          :footer-props="{'items-per-page-options': [15, 25, 50, 100]}"
          @update:options="fetchReport">
          <template v-slot:[`item.orderId`]="{ item }">
            <span
              :class="{
                'color-void': item.currentState === 'void' || item.currentState === 'exchange_return'
              }"> {{ item.orderId }}</span>
          </template>
          <template v-slot:[`item.sumFullPrice`]="{ item }">
            {{ item.sumFullPrice | showNumberFormat() }}
          </template>
          <template v-slot:[`item.unitDiscount`]="{ item }">
            {{ item.unitDiscount | showNumberFormat() }}
          </template>
          <template v-slot:[`item.sumPrice`]="{ item }">
            {{ item.sumPrice | showNumberFormat() }}
          </template>
          <template v-slot:[`item.billDiscount`]="{ item }">
            {{ item.billDiscount | showNumberFormat() }}
          </template>
          <template v-slot:[`item.shipCost`]="{ item }">
            {{ item.shipCost | showNumberFormat() }}
          </template>
          <template v-slot:[`item.net`]="{ item }">
            {{ item.net | showNumberFormat() }}
          </template>
          <template v-slot:[`item.netThb`]="{ item }">
            {{ item.netThb | showNumberFormat() }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        class="d-flex">
        <h2>Shipping Country</h2>
        <v-icon
          class="ml-2"
          color="success"
          :loading="fetching"
          @click="exportCsvShippingCountry">
          mdi-microsoft-excel
        </v-icon>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="shippingCountryHeaders"
          :items="shippingCountry"
          :items-per-page="-1"
          :loading="fetching"
          dense
          hide-default-footer>
          <template v-slot:[`item.countBill`]="{ item }">
            {{ item.countBill | showNumberFormat() }}
          </template>
          <template v-slot:[`item.totalItem`]="{ item }">
            {{ item.totalItem | showNumberFormat() }}
          </template>
          <template v-slot:[`item.totalFullPrice`]="{ item }">
            {{ item.totalFullPrice | showNumberFormat() }}
          </template>
          <template v-slot:[`item.totalBillDiscount`]="{ item }">
            {{ item.totalBillDiscount | showNumberFormat() }}
          </template>
          <template v-slot:[`item.totalShipCost`]="{ item }">
            {{ item.totalShipCost | showNumberFormat() }}
          </template>
          <template v-slot:[`item.totalBillNet`]="{ item }">
            {{ item.totalBillNet | showNumberFormat() }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import NewReportProvider from '@/resources/NewReportProvider'
import { ExportToCsv } from 'export-to-csv'
import ProductAttributeProvider from '@/resources/ProductAttributeProvider'
import DatePicker from '../components/DatesPicker.vue'

const ProductAttributeService = new ProductAttributeProvider()
const NewReportService = new NewReportProvider()

export default {
  components: { DatePicker },
  data () {
    return {
      fetching: false,
      exportLoading: false,
      options: {
        page: 1,
        itemsPerPage: 15,
        sortDesc: [true],
        sortBy: ['createdAt']
      },
      brandSelected: 'all',
      allBrand: [],
      summary: {},
      channel: 'online_singapore',
      channels: [
        {
          label: 'WEB',
          value: 'online_singapore'
        }
      ],
      shippingCountry: [],
      shippingCountryHeaders: [
        { text: 'Country', value: 'id', width: 170 },
        { text: 'Count', value: 'countBill', align: 'right', width: 150 },
        { text: 'Total Item', value: 'totalItem', align: 'right', width: 150 },
        { text: 'Total Full Price', value: 'totalFullPrice', align: 'right', width: 120 },
        { text: 'Total Price', value: 'totalPrice', align: 'right', width: 120 },
        { text: 'Total Bill Discount', value: 'totalBillDiscount', align: 'right', width: 120 },
        { text: 'Total Ship Cost', value: 'totalShipCost', align: 'right', width: 150 },
        { text: 'Total BillNet', value: 'totalBillNet', align: 'right', width: 120 }
      ],
      headers: [
        { text: 'Bill Id', value: 'orderId' },
        { text: 'Create Time', value: 'createdTime', width: 150 },
        { text: 'Item Amount', value: 'itemAmount', align: 'right', width: 150, sortable: false },
        { text: 'Sub Total (SGD)', value: 'sumFullPrice', align: 'right', width: 150, sortable: false },
        { text: 'Unit Discount (SGD)', value: 'unitDiscount', align: 'right', width: 160, sortable: false },
        { text: 'Promotion Code', value: 'promotionCode', align: 'center', width: 150 },
        { text: 'Total (SGD)', value: 'sumPrice', align: 'right', width: 120, sortable: false },
        { text: 'Bill Discount (SGD)', value: 'billDiscount', align: 'right', width: 160 },
        { text: 'Ship Cost (SGD)', value: 'shipCost', align: 'right', width: 150 },
        { text: 'Net (SGD)', value: 'net', align: 'right', width: 150 },
        { text: 'Net THB (rate 23.82)', value: 'netThb', align: 'right', width: 165, sortable: false },
        { text: 'Channel', value: 'channel', align: 'center', width: 150 },
        { text: 'Shipping Country', value: 'shippingCountry', align: 'center', width: 150 }
      ],
      dateSelected: [this.$dayjs().subtract(6, 'day').format(), this.$dayjs().format()],
      items: [],
      total: 0
    }
  },
  watch: {
    dateSelected: {
      handler () {
        this.fetchReport()
      },
      deep: true
    }
  },
  async mounted () {
    await this.getBrand()
    this.fetchReport()
  },
  methods: {
    async getBrand () {
      try {
        const { data } = await ProductAttributeService.getProductAttribute('brands', {
          limit: 999
        })

        this.allBrand = [
        {
          text: 'All',
          value: 'all'
        },
          ...data.results.map((brand) => ({
            text: brand.name,
            value: brand.name
          }))
        ]
      } catch (error) {
        console.error('getAttribute: ', error)
      }
    },
    resetData () {
      this.items = []
      this.summary = {}
      this.shippingCountry = []
      this.total = 0
    },
    async fetchReport () {
      this.resetData()
      this.fetching = true
      await Promise.all([
        this.fetchMainReport(),
        this.fetchReporShippingCountry(),
        this.fetchReportSummary()
      ])
      this.fetching = false
    },
    async fetchReporShippingCountry () {
      try {
        const { data } = await NewReportService.getSummaryShippingCountry({
          channel: this.channel,
          brand: this.brandSelected,
          startDate: this.$dayjs(this.dateSelected[0])
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format(),
          endDate: this.$dayjs(this.dateSelected[1]).add(1, 'day')
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format()
        })
        this.shippingCountry = data
      } catch (err) {
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: `[error on fetch report]: ${err.message}`,
          type: 'error'
        })
      }
    },
    async fetchReportSummary () {
      try {
        const { data } = await NewReportService.getNewReportSummary({
          channel: this.channel,
          brand: this.brandSelected,
          startDate: this.$dayjs(this.dateSelected[0])
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format(),
          endDate: this.$dayjs(this.dateSelected[1]).add(1, 'day')
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format()
        })
        this.summary = data
        this.summary.totalUnitDiscount = this.summary.totalFullPrice - this.summary.totalPrice
        this.summary.totalBillNetTHB = this.summary.totalBillNet * 23.82
      } catch (err) {
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: `[error on fetch report]: ${err.message}`,
          type: 'error'
        })
      }
    },
    async fetchMainReport () {
      try {
        this.fetching = true
        const { data } = await NewReportService.getNewReport({
          page: this.options.page,
          limit: this.options.itemsPerPage,
          sortBy: this.options.sortBy[0],
          sortOrder: this.options.sortDesc[0] ? 'desc' : 'asc',
          channel: this.channel,
          brand: this.brandSelected,
          startDate: this.$dayjs(this.dateSelected[0])
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format(),
          endDate: this.$dayjs(this.dateSelected[1]).add(1, 'day')
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format()
        })
        this.total = data.total
        this.items = data.results.map((re) => ({
          ...re,
          createdTime: this.$dayjs(re.createdTime).format('DD-MM-YYYY HH:mm'),
          unitDiscount: re.sumFullPrice - re.sumPrice,
          netThb: (re.net * 23.82).toFixed(2)
        }))
      } catch (error) {
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: `[error on fetch report]: ${error.message}`,
          type: 'error'
        })
      }
    },
    exportCsvShippingCountry () {
      const startDate = this.$dayjs(this.dateSelected[0])
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format('DD-MM-YYYY')
      const endDate = this.$dayjs(this.dateSelected[1]).add(1, 'day')
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format('DD-MM-YYYY')
      const options = {
          filename: `SHIPPING_COUNTRY_${startDate}_${endDate}_${this.warehouseSelected}`,
          showLabels: true,
          useKeysAsHeaders: true
        }
      const csvExporter = new ExportToCsv(options)

      const data = this.shippingCountry.map((item) => {
        const result = {}
        this.shippingCountryHeaders.forEach((h) => {
          result[h.text] = item[h.value]
        })

        return result
      })
      csvExporter.generateCsv(data)
    },
    download (url, fileName) {
      let tmpFilename = fileName

      if (!fileName) {
        tmpFilename = this.$dayjs().format('YYYYMMDDHHmmss')
      }
      const downloadLink = document.createElement('a')
      downloadLink.href = url
      downloadLink.download = tmpFilename
      downloadLink.click()
    },
    async exportCsv () {
      try {
        this.exportLoading = true
        const { data } = await NewReportService.getExportPro({
          channel: this.channel,
          startDate: this.$dayjs(this.dateSelected[0])
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format(),
          endDate: this.$dayjs(this.dateSelected[1]).add(1, 'day')
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format()
        })
        this.download(data)
      } catch (err) {
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: `[error on fetch report]: ${err.message}`,
          type: 'error'
        })
      } finally {
        this.exportLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/gw-variables.scss';
.color-void {
  color: $bill-status-void;
}
</style>
